import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography, Button, Divider } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

export class Task extends React.Component{
    render() {
        return (
          <Box mt="15px">
              <Grid container spacing={1} wrap="nowrap"  alignItems="center" alignContent="space-between">
               <Grid item xs>
                  <Box px="8px"><Typography align="left">{this.props.task}</Typography></Box>
                </Grid>
                <Grid item xs='auto'>
                <Grid container spacing={0} alignItems="center" alignContent="flex-end" justify="flex-end">
                <Divider orientation="vertical" flexItem />
                  <Grid item>
                    <Button color="primary"><DoneIcon /></Button>
                  </Grid>
                  <Grid item>
                    <Button color="secondary" onClick={() => {this.props.delete(this.props.id)}}><DeleteForeverIcon /></Button>
                  </Grid>
                </Grid>             
                </Grid>
              </Grid>
          </Box>
          );
    }
}
