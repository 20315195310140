import React from 'react';
import logo from '../logo.svg';
import './App.css';
import './reset.css';
import {List} from '../Components/List';
import { Container, Paper, Box } from '@material-ui/core';

function App() {
  return (
    <div className="App">
      <Container height="100%">
        <Paper>
          <Box p="15px" pt="0" mt="10vh">
            <List />
          </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default App;
