import { render } from '@testing-library/react';
import React from 'react';
import { Task } from './Task';
import { TextField, Button, Grid, Box, Divider } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

export class List extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tasks: {},
            newTask: "",
            taskId: 0
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
    }

    handleClick() {
        if(this.state.newTask==="") return;
        let tasks = this.state.tasks;
        let id = this.state.taskId + 1;
        let task = <Task id={id} task={this.state.newTask} delete={this.deleteTask}/>;
        tasks[id] = task;
        let tasksArray = [];
        for(let i=0 ; i < this.state.taskId + 2 ; i++) {
            if(this.state.tasks[i]) tasksArray.push(this.state.tasks[i]);
        }
        this.setState({tasks: tasks, newTask: "", tasksArray: tasksArray, taskId: id});
    }

    handleChange(e) {
        this.setState({newTask: e.target.value});
    }

    deleteTask(id) {
        let tempTasks = this.state.tasks;
        tempTasks[id] = null;
        let tasksArray = [];
        for(let i=0;i<this.state.taskId + 1;i++) {
            if(this.state.tasks[i]) tasksArray.push(this.state.tasks[i]);
        }
        this.setState({tasksArray: tasksArray});
    }

    render(){
        return (<Box height="80vh" display="flex" flexDirection="column">
            <Box display="block" pt="15px" flexGrow={1} width="100%">

                {this.state.tasksArray}
                
            </Box>
            <Divider />
            <Box display="flex" alignItems="flex-end" xs="auto" marginTop="15px">
                <Grid container spacing={1} wrap="nowrap"  alignItems="center">
                    <Grid item xs>
                        <Box>
                            <form noValidate autoComplete="off">
                                <TextField value={this.state.newTask} onChange={this.handleChange} id="outlined-basic" label="Add Task" variant="outlined" fullWidth={true} xs/>
                            </form>
                        </Box>
                    </Grid>
                    <Grid item xs='auto'>
                        <Box><Button size="x-large" onClick={this.handleClick}><SendIcon/></Button></Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>)
    }
}